"use client"

import { useEffect } from 'react'

const throttle = (func, limit) => {
  let inThrottle
  return (...args) => {
    if (!inThrottle) {
      func(...args)
      inThrottle = true
      setTimeout(() => inThrottle = false, limit)
    }
  }
}

const handleGtagEvent = (event) => {
  const target = event.target.closest('[data-gtag-event]')

  if (!target) return;

  const eventName = target.getAttribute('data-gtag-event')
  const eventParams = target.getAttribute('data-gtag-params')
  let parsedParams = {}

  try {
    parsedParams = eventParams ? JSON.parse(eventParams) : {}
  } catch (error) {
    console.error('Failed to parse data-gtag-params:', error)
  }


  if (window?.gtag && process.env.ENV === 'production') {
    window.gtag('event', eventName, Object.keys(parsedParams).length ? parsedParams : undefined)
  }
}

const throttledHandleGtagEvent = throttle(handleGtagEvent, 1000)

const ClientEventHandler = () => {
  useEffect(() => {
    document.body.addEventListener('click', throttledHandleGtagEvent)

    return () => {
      document.body.removeEventListener('click', throttledHandleGtagEvent)
    }
  }, [])

  return null
}

export default ClientEventHandler